<template>
  <div class="trainingPage">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
name: "trainingPage"
}
</script>

<style scoped>
  .trainingPage {
    width: 100vw;
    height: 87vh;
    overflow: hidden;
  }
</style>